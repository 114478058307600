import { React, useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
// import { Breadcrumb } from "react-bootstrap";
import jwt_decode from "jwt-decode";

import { Container,Skeleton,Button } from "@mui/material";
var moment = require('moment');
const VideoPlay = () => {
  const params = useParams();
  const navigate = useNavigate();
  try {
    var decoded = jwt_decode(localStorage.getItem("token"));
  } catch {
    navigate("/");
  }
  const [usersVideo, setUsersVideo] = useState();
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [roleId, setRoleId] = useState(
    decoded != undefined ? decoded.CreateUserToken.role_id : null
  );
  const [videoSrc, setVideoSrc] = useState();
  const { state } = useLocation();
  if (state != undefined) {
    localStorage.setItem("UserId", state.user_id);
  }
  useEffect(() => {
    GetVideoDetails();
  }, []);

  // useEffect(() => {
  //   if(usersVideo != undefined){
  //   display();
  //   }
  // }, [usersVideo]);

  async function GetVideoDetails() {
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/getVideoByVideoId", {
      method: "GET",
      headers: {
        "X-access-token": `Bearer ${localStorage.getItem("token")}`,
        VideoId: params.id,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          if (!data.Video) {
            setIsAuthorized(data.Video);
          } else {
            setUsersVideo(data.Video[0]);
          }
        } else {
          localStorage.removeItem("token");
          navigate("/");
        }
        return data;
      });
  }
  const handleBack = () => {
    if(roleId != null && roleId != 3){
    navigate("/patient/id/" + localStorage.getItem("UserId"));
    }else{
    navigate("/dashboard");
    }
  };



  function revokeURL(videoUrl) {
    const videoElement = document.querySelector("video");
    videoElement.removeAttribute("src");
    // return URL.revokeObjectURL(videoUrl);     // remove URL reference
  }

  return (
    <div>
      <Container maxWidth="lg">
      <>
        {!isAuthorized ? (
          <div className="no-video">
          You Don't have access
          </div>
        ) : (
              <>
              <div className='video-name-date'>
                <div className="video-name">
            {usersVideo != undefined ? (
                usersVideo.title
            ) : (<Skeleton className="video_Skeleton" variant="rounded" height={52} width={495} />)}
                </div>
                <div className="video-date">
            {usersVideo != undefined ? (
                moment(usersVideo.created_on).format('MMM DD, YYYY')
            ) : (<Skeleton className="video_Skeleton" variant="rounded" height={27} width={107} />)}
                </div>
                </div>
                <div className="video-cls">
            {usersVideo != undefined ? (
                <video
                  className="VideoInput_video"
                  width="100%"
                  controls
                  poster={process.env.REACT_APP_NODE_API_URL+"/assets/thumbnail/" + usersVideo.thumbnail}
                  controlsList="nodownload"
                  id="_video"
                  muted 
                  loop
                  playsinline
                  type=""
                  src={process.env.REACT_APP_NODE_API_URL+"/assets/Videos/" + usersVideo.video}
                  onLoadedData={() => revokeURL()}
                />) : (<Skeleton className="video_Skeleton" variant="rounded" height={500} width={1032} />)}
                </div>
                <div className="video-description">
            {usersVideo != undefined ? (
                  usersVideo.description ) : ('')}
                  </div>
              </>
        )}
      </>
      <div className="patient-back">
      <Button className='patient-back-button' onClick={handleBack}>BACK</Button>
      </div>
      </Container>
      
    </div>
  );
};

export default VideoPlay;
