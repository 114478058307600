// routes
import "./App.css";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Header from "./components/Section/Header";
import Footer from "./components/Section/Footer";
// ----------------------------------------------------------------------

export default function App() {
 
  return (
    <ThemeProvider>
      <Header />
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <Footer />
    </ThemeProvider>
  );
}
