import { filter } from 'lodash';
import { useEffect,useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Name', alignRight: false },
  { id: 'birthdate', label: 'BirthDate', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DoctorDashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    GetUsersList();
  }, []);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [usersData, setUsersData] = useState();


  async function GetUsersList() {
    // const cacheStorage = await caches.open('usersData');
    // const cachedResponse = await cacheStorage.match(window.location.href);
    // console.log(cachedResponse)
    
    // // If no cache exists
    // if (!cachedResponse || !cachedResponse.ok) {
    //   // setCacheData('Fetched failed!')
    // }
  
    //  cachedResponse.json().then((item) => {
    //   console.log(item)
    //   // setCacheData(item)
    // });
   
    await fetch(process.env.REACT_APP_NODE_API_URL+"/api/getusers", {
      method: "GET",
      headers: {
        "X-access-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          // const databnew = new Response(JSON.stringify(data.users));

          // if ('caches' in window) {
          //   // Opening given cache and putting our data into it
          //   caches.open("usersData").then((cache) => {
          //     cache.put(window.location.href, databnew);
          //     alert('Data Added into cache!')
          //   });
          // }
          setUsersData(data.users);
        } else {
          localStorage.removeItem("token");
          navigate("/");
        }
        return data;
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  let filteredUsers
  let emptyRows
  if(usersData != undefined){
   filteredUsers = applySortFilter(usersData, getComparator(order, orderBy), filterName);
   emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;
  }else{
    filteredUsers = []
    emptyRows = []
  }
 
  const isUserNotFound = filteredUsers.length === 0;

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
  }

  return (
    <Page title="User">
      <Container maxWidth="lg">
      <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
      {usersData != undefined ? (
        <Card  className='table-bg'>
          <Scrollbar className=''>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usersData != undefined ? usersData.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody className='table-body'>
                  {usersData != undefined ? (
                  filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, birthdate, phone, email} = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                       
                        <TableCell align="left"><Link to={'/patient/id/'+id}>{name}</Link></TableCell>
                        <TableCell align="left">{birthdate}</TableCell>
                        <TableCell align="left">{formatPhoneNumber(phone)}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                      </TableRow>
                    );
                  })
                  ) : ('')}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {usersData != undefined && isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={usersData != undefined ? filteredUsers.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        ) : (<Skeleton variant="rounded" height={363} width={1152} />)}   
      </Container>
    </Page>
  );
}
