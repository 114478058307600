import { useState, useEffect } from 'react';
import { LinearProgress, Box, Button, TextField, TextareaAutosize } from '@mui/material';
import axios from 'axios';
import { useForm } from 'react-hook-form';

export default function VideoUpload(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { height } = props;
  const [source, setSource] = useState('');
  const [video, setVideo] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [progress, setProgress] = useState(0);
  const [isDisabled, setIsDisabled] = useState('');
  const [isLineBar, setIsLineBar] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const handleFileChange = async () => {
    setIsLineBar(true)
    const formData = new FormData();
    setIsDisabled('disabled')
    setProgress(10);

    const VideoData = {
      videoTitle: videoTitle,
      videoDescription: videoDescription,
    };

    formData.append('video', video);
    formData.append('image', thumbnail);
    axios
      .post(process.env.REACT_APP_NODE_API_URL+'/api/uploadVideo', formData, {
        headers: {
          'X-access-token': `Bearer ${localStorage.getItem("token")}`,
          patient_id: props.patienId,
          videoData: JSON.stringify(VideoData),
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.code == 200) {
          props.function(false);
        }
        return response;
      });
  };

  const handleError = (errors) => {};
  const FileOption = {
    file: { required: 'This field is required' },
    title: { required: 'This field is required' },
  };

  async function SetVideo(e) {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setVideo(file);
    setSource(url);
    const thumbnail =  await generateVideoThumbnail(file);
    var block = thumbnail.split(";");
// Get the content type of the image
var contentType = block[0].split(":")[1];// In this case "image/gif"
// get the real base64 content of the file
var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

// Convert it to a blob to upload
var blob = b64toBlob(realData, contentType);
setThumbnail(blob)
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
  
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
  
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
   
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  return (
    <div className="VideoInput">
        <form onSubmit={handleSubmit(handleFileChange, handleError)}>
      <TextField className='choose-file' name='file' {...register('file', FileOption.file)} type="file" onChange={(e) => SetVideo(e)} />
      <small className="text-danger">{errors?.file && errors.file.message}</small>
      {/* {!isValidVideo ? (
      <small className="text-danger">Format does not support.</small>
      ) : ('')} */}
      {source && <video className="VideoInput_video" width="100%" height={height} controls src={source} />}
      <TextField name='title' {...register('title', FileOption.title)} className='video-upload-text' type="text" onChange={(e) => setVideoTitle(e.target.value)} placeholder="Video Title" />
      <small className="text-danger">{errors?.title && errors.title.message}</small>
      <TextareaAutosize className='video-upload-textarea'
      minRows={4}
      onChange={(e) => setVideoDescription(e.target.value)}
      placeholder="Below Video Copy"
      style={{ width: 200 }}
    />
     <Box sx={{}}>
        {isLineBar && <LinearProgress variant="determinate" value={progress} />}
      </Box>
      <Button className='video-uplode-button' disabled={isDisabled} type='submit'>SEND VIDEO</Button>
      </form>
    </div>
  );
}
